<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('purchaseDocument.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('purchaseDocument.status') }}
      </v-col>
      <v-col cols="6">
        {{ detail.status }}
      </v-col>
    </v-row>
    <v-row
      class="justify-center mt-6 mb-4"
      v-if="permissions.includes('EDIT') && detail.status !== 'EMPTY'"
    >
      <v-btn type="button" color="success" @click="openPurchaseDocumentForm">
        {{ $t('purchaseDocument.review') }}
      </v-btn>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    openPurchaseDocumentForm() {
      this.$modal.show('modal-purchase-document', {
        purchaseDocument: this.detail,
      });
    },
  },
};
</script>
